<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import Gen from '@helper/Gen';
	import draggable from 'vuedraggable';
	// import Popper from 'vue-popperjs';
	// import 'vue-popperjs/dist/vue-popper.css';

	export default {
		name: "BoAboutUs",
		extends: Base,
		components: {
			draggable
		},
		data() {
			return {
				Name: "BoAboutUs",
				row: {
					am_name: '',
					am_image: '',
					am_wa: '',
					am_position: '',
					am_mail: '',
					am_is_active: '',
					am_large_img: ''
				},
				rowCity: {},
				row1: {},
				row2: {},
				row3: {
					ac_name: '',
					ac_address: '',
					ac_x: '',
					ac_y: ''
				},
				font_style: {},
				font_size: {},
				sub_font_size: {},
				typeForm: 'add',
				dataMap: [],
				data2: {},
				city: {},
				positions: {
					clientX: undefined,
					clientY: undefined,
					movementX: 0,
					movementY: 0
				}
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
		},
		methods: {
			initPopover() {
				$("[data-toggle=popover]").popover()
			},
			deleteItem(e, k) {
				console.log(e)
				var v = this.dataMap[k]
				swal({
					title: "Are you sure to delete city?",
					text: "",
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, Sure!"],
					dangerMode: true,
				}).then((ok) => {
					if (ok) {
						BOGen.apirest("/" + this.Name, {
							type: "delete2",
							id: v.id
						}, (err, resp) => {
							if (err) return swal(err.resp.message, "", "warning")
							$(e.target).parents('tr').addClass("animated bounceOutRight");
							setTimeout(() => {
								$(e.target).parents('tr').removeClass("animated bounceOutRight");
								this.dataMap.splice(k, 1);
							}, 1000);
							swal("Deleted!", resp.message, "success");
						}, "POST")

					}
				});
			},
			deleteTeam(e, k) {
				var v = this.data.data[k]
				console.log(v)
				swal({
					title: "Are you sure to delete team?",
					text: "",
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, Sure!"],
					dangerMode: true,
				}).then((ok) => {
					if (ok) {
						BOGen.apirest("/" + this.Name, {
							type: "delete",
							id: v.id
						}, (err, resp) => {
							if (err) return swal(err.resp.message, "", "warning")
							$(e.target).parents('tr').addClass("animated bounceOutRight");
							setTimeout(() => {
								$(e.target).parents('tr').removeClass("animated bounceOutRight");
								this.data.data.splice(k, 1);
							}, 1000);
							swal("Deleted!", resp.message, "success");
							this.refreshData()
						}, "POST")

					}
				});
			},
			deleteTeam2(e, k) {
				var v = this.data2.data[k]
				console.log(v)
				swal({
					title: "Are you sure to delete team?",
					text: "",
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, Sure!"],
					dangerMode: true,
				}).then((ok) => {
					if (ok) {
						BOGen.apirest("/" + this.Name, {
							type: "delete",
							id: v.id
						}, (err, resp) => {
							if (err) return swal(err.resp.message, "", "warning")
							$(e.target).parents('tr').addClass("animated bounceOutRight");
							setTimeout(() => {
								$(e.target).parents('tr').removeClass("animated bounceOutRight");
								this.data.data.splice(k, 1);
							}, 1000);
							swal("Deleted!", resp.message, "success");
							this.refreshData()
						}, "POST")

					}
				});
			},
			submitMap() {
				this.row3.type = this.typeForm
				BOGen.apirest('/' + this.Name, this.row3, (err, resp) => {
					if (resp.error) return Gen.info(resp.message, 'danger', 3000, '.info-modal')
					if (resp.success) {
						Gen.info(resp.message, 'success', 2000, '.info-modal')
						this.refreshData()
						setTimeout(() => {
							this.initPopover()
						}, 500)
						setTimeout(() => {
							$('#add_city').modal('hide')
						}, 2100)
						this.this.row3 = {}
					}
				}, "POST");
			},
			submitCity() {
				this.rowCity.type = 'addCity'
				BOGen.apirest('/' + this.Name, this.rowCity, (err, resp) => {
					if (resp.error) return Gen.info(resp.message, 'danger', 3000, '.info-modal')
					if (resp.success) {
						Gen.info(resp.message, 'success', 2000, '.info-modal')
						this.refreshData()
						setTimeout(() => {
							this.initPopover()
						}, 500)
						setTimeout(() => {
							$('#add_master_city').modal('hide')
						}, 2100)
						this.this.rowCity = {}
					}
				}, "POST");
			},
			submitFormTeam() {
				this.row.type = this.typeForm
				BOGen.apirest('/' + this.Name, this.row, (err, resp) => {
					if (resp.error) return Gen.info(resp.message, 'danger', 3000, '.info')
					if (resp.success) {
						Gen.info(resp.message, 'success', 2000, '.info')
						this.refreshData()
						setTimeout(() => {
							$('#add_team').modal('hide')
						}, 2100)
					}
				}, "POST");
			},
			submitHero() {
				BOGen.apirest('/' + this.Name, {
					data: this.row1,
					type: 'updateHero'
				}, (err, resp) => {
					if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.hero-info')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 2000, '.hero-info')
						this.refreshData()
						setTimeout(() => {
							$('[href="#collapseExample"]').click()
						}, 2100)
					}
				}, "POST");
			},
			submitAbout() {
				BOGen.apirest('/' + this.Name, {
					data: this.row2,
					type: 'updateAbout'
				}, (err, resp) => {
					if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.about-info')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 2000, '.about-info')
						this.refreshData()
					}
				}, "POST");
			},
			endDrag() {
				BOGen.apirest('/' + this.Name, {
					data: this.data.data,
					type: 'sort'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			},
			endDrag2() {
				BOGen.apirest('/' + this.Name, {
					data: this.data2.data,
					type: 'sort2'
				}, (err, resp) => {
					this.refreshData()
				}, "POST");
			},
			addTeam() {
				this.row = {
					am_image: '',
					am_name: '',
					am_position: '',
					am_mail: '',
					am_wa: ''
				}
				$('#add_team').modal();
				this.row.am_large_img = 'N'
				this.row.am_is_active = 'Y'
				this.typeForm = 'add'
			},
			editData(v) {
				$('#add_team').modal();
				this.typeForm = 'update'
				BOGen.apirest('/' + this.Name, {
					id: v.am_id,
					type: 'getUpdate'
				}, (err, resp) => {
					this.row = resp;
				}, 'POST');
			},
			addMap(event) {
				this.typeForm = 'addMap'
				this.row3 = {
					ac_mrc_id: '',
					ac_name: '',
					ac_address: '',
					ac_x: '',
					ac_y: ''
				}
				var x = event.pageX - $('.map_indo').offset().left;
				var y = event.pageY - $('.map_indo').offset().top;
				var leftPercent = x / $('.map_indo').width() * 100;
				var topPercent = y / $('.map_indo').height() * 100;

				$("#addCity .map_indo .point_map:not([data-toggle])").remove();
				$('.map_indo').append("<a class='point_map a1' style='top: " + topPercent + "%;  left: " + leftPercent +
					"%;'>");
				setTimeout(() => {
					$('#add_city').modal();
					$('.point_map').last().remove();
				}, 250);
				console.log(leftPercent + "%, " + topPercent + "%");
				this.row3.ac_x = leftPercent
				this.row3.ac_y = topPercent
			},
			editMap(v) {
				console.log(v)
				setTimeout(() => {
					this.typeForm = 'updateMap'
				}, 300)
				$('#add_city').modal();
				this.row3 = v
			}
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<div class="row">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<VForm @resp="submitHero" method="post">
						<div class="card-body">
							<h5 class="card-title">Hero Image</h5>
							<div class="row">
								<div class="col-md-6">
									<div class="wrap_slider_img">
										<img :src="uploader(row1.ash_image_desktop)" class="img-responsive" />
										<div class="slider_name">
											<p>Hero Image</p>
										</div>
										<a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
											aria-controls="collapseExample" class="bullet_edit"><i class="fas fa-pencil-alt"></i></a>
									</div>
								</div>
							</div>
							<div class="row collapse mt-4" id="collapseExample">
								<div class="hero-info col-12"></div>
								<div class="col-md-4 mb-3">
									<BoField name="ash_image_desktop" mandatory>
										<Uploader :param="{thumbnail:true}" name="ash_image_desktop" v-model="row1.ash_image_desktop"
											type="hero_descktop" uploadType="cropping">
										</Uploader>
									</BoField>
									<BoField name="ash_image_mobile" mandatory>
										<Uploader :param="{thumbnail:true}" name="ash_image_mobile" v-model="row1.ash_image_mobile"
											type="hero_mobile" uploadType="cropping">
										</Uploader>
									</BoField>
								</div>
								<div class="col-md-8 mb-3">
									<div class="row">
										<div class="col-md-12">
											<div class="panel_group">
												<div class="panel_heading">Heading</div>
												<div class="panel_body">
													<div class="row">
														<div class="col-md-9">
															<BoField name="ash_title_en" v-model="row1.ash_title_en">
															</BoField>
														</div>
														<div class="col-md-9">
															<BoField name="ash_title_id" v-model="row1.ash_title_id">
															</BoField>
														</div>
														<div class="col-md-4">
															<div class="form-group">
																<label class="control-label">Font Style</label>
																<select class="form-control" v-model="row1.ash_title_font_type">
																	<option v-for="(v,k) in font_style" :key="k" :value="k">{{v}}</option>
																</select>
															</div>
														</div>
														<div class="col-md-2">
															<div class="form-group">
																<label class="control-label">Font Size </label>
																<select class="form-control" v-model="row1.ash_title_size">
																	<option v-for="(v,k) in font_size" :key="k" :value="v">{{v}}</option>
																</select>
															</div>
														</div>
														<div class="col-md-2">
															<BoField :attr="{type:'color'}" name="ash_title_color" v-model="row1.ash_title_color">
															</BoField>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-12">
											<div class="row">
												<div class="col-md-12">
													<div class="panel_group">
														<div class="panel_heading">Sub-Heading</div>
														<div class="panel_body">
															<div class="row">
																<div class="col-md-9">
																	<div class="form-group">
																		<BoField name="ash_sub_title_en" v-model="row1.ash_sub_title_en"></BoField>
																	</div>
																</div>
																<div class="col-md-9">
																	<BoField name="ash_sub_title_id" v-model="row1.ash_sub_title_id"></BoField>
																</div>
																<div class="col-md-4">
																	<div class="form-group">
																		<label class="control-label">Font Style</label>
																		<select class="form-control" v-model="row1.ash_sub_title_font_type">
																			<option v-for="(v,k) in font_style" :key="k" :value="k">{{v}}</option>
																		</select>
																	</div>
																</div>
																<div class="col-md-2">
																	<div class="form-group">
																		<label class="control-label">Font Size </label>
																		<select class="form-control" v-model="row1.ash_sub_title_size">
																			<option v-for="(v,k) in sub_font_size" :key="k" :value="v">{{v}}</option>
																		</select>
																	</div>
																</div>
																<div class="col-md-2">
																	<div class="form-group">
																		<label class="control-label">Color </label>
																		<input class="form-control" type="color" id="favcolor" name="favcolor"
																			v-model="row1.ash_sub_title_color">
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-4 mt-3">
											<button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</VForm>
				</div>
			</div>
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="submitAbout" method="post">
							<div class="form-body">
								<h5 class="card-title">Our Story</h5>
								<hr>
								<div class="row">
									<div class="col-12 about-info"></div>
									<div class="col-md-9">
										<BoField v-bind="validation('asc_title_en')"
											:attr="{placeholder:'e.g. DIMATIQUE IS A SUBSIDIARY OF PT DIMA GROUP.'}" name="asc_title_en"
											v-model="row2.asc_title_en"></BoField>
										<BoField mandatory name="asc_desc_en">
											<CKEditor name="asc_desc_en" v-model="row2.asc_desc_en" v-bind="validation('asc_desc_en')">
											</CKEditor>
										</BoField>
									</div>
									<div class="col-md-3">
										<BoField name="asc_img1" mandatory>
											<Uploader :param="{thumbnail:true}" name="asc_img1" v-model="row2.asc_img1" type="about"
												uploadType="cropping"></Uploader>
										</BoField>
									</div>
									<div class="col-md-9">
										<BoField :attr="{placeholder:'e.g. DIMATIQUE IS A SUBSIDIARY OF PT DIMA GROUP.'}"
											name="asc_title_id" v-model="row2.asc_title_id"></BoField>
										<BoField mandatory name="asc_desc_id">
											<CKEditor name="asc_desc_id" v-model="row2.asc_desc_id" v-bind="validation('asc_desc_id')">
											</CKEditor>
										</BoField>
										<!-- <BoField name="asc_show_views">
											<div class="col-6">
												<div class="row">
													<div class="col-2">
														<radio name="asc_show_views" v-model="row2.asc_show_views" option="Y"
															:attr="validation('asc_show_views')">Yes</radio>
													</div>
													<div class="col-2">
														<radio name="asc_show_views" v-model="row2.asc_show_views" option="N">No</radio>
													</div>
												</div>
											</div>
										</BoField> -->
									</div>
									<div class="col-md-3">
										<BoField name="asc_img2" mandatory>
											<Uploader :param="{thumbnail:true}" name="asc_img2" v-model="row2.asc_img2" type="about"
												uploadType="cropping"></Uploader>
										</BoField>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12 pull-right">
										<button type="submit" class="btn  btn-rounded  float-right btn-info btn-loading">Submit</button>
									</div>
								</div>
							</div>
						</VForm>
					</div>
				</div>
			</div>
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<form action="#">
							<div class="form-body">
								<h5 class="card-title">Our Team (Large Image)</h5>
								<hr>
								<div class="row">
									<div class="col-md-12">
										<div class="row">
											<div class="col-md-12">
												<!-- <div class="table-responsive m-t-30">
													<table class="table product-overview">
														<thead>
															<tr>
																<th>#</th>
																<th>Fullname</th>
																<th>Photo</th>
																<th>Jabatan</th>
																<th>Email</th>
																<th>WhatsApp</th>
																<th>Status</th>
																<th>Action</th>
															</tr>
														</thead>
														<draggable v-model="data.data" tag="tbody" @end="endDrag" style="cursor:pointer;">
															<tr v-for="(v,k) in data.data" :key="k">
																<td class="number">
																	{{(data.per_page*(data.current_page-1))+k+1}}</td>
																<td>{{v.am_name}}</td>
																<td>
																	<div class="team_wrap_thumb">
																		<img :src="uploader(v.am_image)" class="img-responsive" />
																	</div>
																</td>
																<td>{{v.am_position}}</td>
																<td>{{v.am_mail}}</td>
																<td>{{v.am_wa}}</td>
																<td>
																	<StatusLabel :status="v.status"></StatusLabel>
																</td>
																<td class="btn-action">
																	<a href="javascript:;" class="icon_action" @click="editData(v)" v-tooltip="'Edit'"><i
																			class="ti-marker-alt"></i></a>
																	<a href="javascript:;" class="icon_action" @click="changeStatus(k)"
																		v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
																	<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)"
																		v-tooltip="'Remove'"><i class="ti-trash"></i></a>
																</td>
															</tr>
															<tr v-if="NotFound">
																<td colspan="99">
																	<h3 class="tc">Our Team Not Found</h3>
																</td>
															</tr>
															<tr v-if="data.data===false">
																<td colspan="99">
																	<LoadingSpinner light></LoadingSpinner>
																</td>
															</tr>
														</draggable>
													</table>
												</div> -->
												<draggable v-model="data.data" class="row" @end="endDrag" style="cursor:pointer;">
													<div class="col-md-3" v-for="(v,k) in data.data" :key="k">
														<div class="card">
															<div class="card-body">
																<div class="product-img our-team">
																	<img :src="uploader(v.am_image)" class="img-responsive" />
																	<div class="pro-img-overlay">
																		<a href="javascript:;" @click="editData(v)" v-tooltip="'Edit'" class="bg-info"><i
																				class="fas fa-pencil-alt"></i></a>
																		<!-- <a href="javascript:;" @click="editData(v)"
																			v-tooltip="'Change Status'"
																			class="bg-warning"><i
																				class="fas fa-cog"></i></a> -->
																		<a href="javascript:void(0)" class="bg-danger" @click="deleteTeam($event,k)"
																			v-tooltip="'Remove'"><i class="ti-trash"></i></a>
																	</div>
																	<StatusLabel :status="v.status"></StatusLabel>
																</div>
																<div class="product-text text-center border-0">
																	<h6 class="card-title mb-0">{{v.am_name}}</h6>
																	<span>{{v.am_position}}</span>
																	<div class="team-contact">
																		<p v-if="v.am_mail"><i class="far fa-envelope"></i>{{v.am_mail}}
																		</p>
																		<p v-if="v.am_wa"><i class="fab fa-whatsapp"></i>{{v.am_wa}}
																		</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</draggable>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12 float-right">
										<button type="button" @click="addTeam" class="btn btn-info btn-rounded float-right"><i
												class="fa fa-plus-circle mr-2"></i> Add New Team</button>
									</div>
								</div>
								<div id="add_team" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_team"
									aria-hidden="true">
									<div class="modal-dialog  modal-lg">
										<div class="modal-content">
											<div class="modal-header">
												<h4 class="modal-title" id="prod_cat">{{typeForm=='add'?'Add':'Edit'}}
													Team</h4>
												<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
											</div>
											<VForm @resp="submitFormTeam" method="post">
												<div class="modal-body">
													<div class="row">
														<div class="col-12 info"></div>
														<div class="col-4">
															<BoField name="am_image" mandatory>
																<Uploader :param="{thumbnail:true}" name="am_image" v-model="row.am_image" type="team"
																	uploadType="cropping"></Uploader>
															</BoField>
														</div>
														<div class="col-8">
															<BoField mandatory v-bind="validation('am_name')"
																:attr="{title:'Input Full Name',required:'required'}" name="am_name"
																v-model="row.am_name"></BoField>
															<BoField mandatory v-bind="validation('am_position')"
																:attr="{placeholder:'e.g. CEO.',title:'Input Position',required:'required'}"
																name="am_position" v-model="row.am_position"></BoField>
															<BoField v-bind="validation('am_mail')" :attr="{type:'email'}" name="am_mail"
																v-model="row.am_mail"></BoField>
															<BoField v-bind="validation('am_wa')" :attr="{type:'number'}" name="am_wa"
																v-model="row.am_wa"></BoField>
															<BoField name="am_large_img">
																<div class="row">
																	<div class="col-6">
																		<div class="row">
																			<radio name="am_large_img" :id="'i'" v-model="row.am_large_img" option="Y"
																				:attr="validation('am_large_img')">Large
																			</radio>
																			<radio name="am_large_img" :id="'n'" v-model="row.am_large_img" option="N">
																				Small
																			</radio>
																		</div>
																	</div>
																</div>
															</BoField>
															<BoField name="am_is_active">
																<div class="row">
																	<div class="col-6">
																		<div class="row">
																			<radio name="am_is_active" :id="'ii'" v-model="row.am_is_active" option="Y"
																				:attr="validation('am_is_active')">
																				Active</radio>
																			<radio name="am_is_active" :id="'nn'" v-model="row.am_is_active" option="N">
																				Inactive</radio>
																		</div>
																	</div>
																</div>
															</BoField>
														</div>
													</div>
												</div>
												<div class="modal-footer">
													<button type="submit" class="btn btn-rounded  btn-info btn-loading">Submit</button>
												</div>
											</VForm>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<form action="#">
							<div class="form-body">
								<h5 class="card-title">Our Team (Small Image)</h5>
								<hr>
								<div class="row">
									<div class="col-md-12">
										<div class="row">
											<div class="col-md-12">
												<!-- <div class="table-responsive m-t-30">
													<table class="table product-overview">
														<thead>
															<tr>
																<th>#</th>
																<th>Fullname</th>
																<th>Photo</th>
																<th>Jabatan</th>
																<th>Email</th>
																<th>WhatsApp</th>
																<th>Status</th>
																<th>Action</th>
															</tr>
														</thead>
														<draggable v-model="data2.data" tag="tbody" @end="endDrag2" style="cursor:pointer;">
															<tr v-for="(v,k) in data2.data" :key="k">
																<td class="number">{{k+1}}</td>
																<td>{{v.am_name}}</td>
																<td>
																	<div class="team_wrap_thumb">
																		<img :src="uploader(v.am_image)" class="img-responsive" />
																	</div>
																</td>
																<td>{{v.am_position}}</td>
																<td>{{v.am_mail}}</td>
																<td>{{v.am_wa}}</td>
																<td>
																	<StatusLabel :status="v.status"></StatusLabel>
																</td>
																<td class="btn-action">
																	<a href="javascript:;" class="icon_action" @click="editData(v)" v-tooltip="'Edit'"><i
																			class="ti-marker-alt"></i></a>
																	<a href="javascript:;" class="icon_action" @click="changeStatus(k)"
																		v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
																	<a href="javascript:;" class="icon_action" @click="deleteItem2($event,k)"
																		v-tooltip="'Remove'"><i class="ti-trash"></i></a>
																</td>
															</tr>
															<tr v-if="NotFound">
																<td colspan="99">
																	<h3 class="tc">Our Team Not Found</h3>
																</td>
															</tr>
															<tr v-if="data.data===false">
																<td colspan="99">
																	<LoadingSpinner light></LoadingSpinner>
																</td>
															</tr>
														</draggable>
													</table>
												</div> -->

												<draggable v-model="data2.data" class="row" @end="endDrag" style="cursor:pointer;">
													<div class="col-md-3" v-for="(v,k) in data2.data" :key="k">
														<div class="card">
															<div class="card-body">
																<div class="product-img our-team">
																	<img :src="uploader(v.am_image)" class="img-responsive" />
																	<div class="pro-img-overlay">
																		<a href="javascript:;" @click="editData(v)" v-tooltip="'Edit'" class="bg-info"><i
																				class="fas fa-pencil-alt"></i></a>
																		<!-- <a href="javascript:;" @click="editData(v)"
																			v-tooltip="'Change Status'"
																			class="bg-warning"><i
																				class="fas fa-cog"></i></a> -->
																		<a href="javascript:void(0)" class="bg-danger" @click="deleteTeam2($event,k)"
																			v-tooltip="'Remove'"><i class="ti-trash"></i></a>
																	</div>
																	<StatusLabel :status="v.status"></StatusLabel>
																</div>
																<div class="product-text text-center border-0">
																	<h6 class="card-title mb-0">{{v.am_name}}</h6>
																	<span>{{v.am_position}}</span>
																	<div class="team-contact">
																		<p v-if="v.am_mail"><i class="far fa-envelope"></i>{{v.am_mail}}
																		</p>
																		<p v-if="v.am_wa"><i class="fab fa-whatsapp"></i>{{v.am_wa}}
																		</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</draggable>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12 float-right">
										<button type="button" @click="addTeam" class="btn btn-info btn-rounded float-right"><i
												class="fa fa-plus-circle mr-2"></i> Add New Team</button>
									</div>
								</div>
								<div id="add_team" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_team"
									aria-hidden="true">
									<div class="modal-dialog  modal-lg">
										<div class="modal-content">
											<div class="modal-header">
												<h4 class="modal-title" id="prod_cat">{{typeForm=='add'?'Add':'Edit'}}
													Team</h4>
												<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
											</div>
											<VForm @resp="submitFormTeam" method="post">
												<div class="modal-body">
													<div class="row">
														<div class="col-12 info"></div>
														<div class="col-4">
															<BoField name="am_image" mandatory>
																<Uploader :param="{thumbnail:true}" name="am_image" v-model="row.am_image" type="team"
																	uploadType="cropping"></Uploader>
															</BoField>
														</div>
														<div class="col-8">
															<BoField mandatory v-bind="validation('am_name')"
																:attr="{title:'Input Full Name',required:'required'}" name="am_name"
																v-model="row.am_name"></BoField>
															<BoField mandatory v-bind="validation('am_position')"
																:attr="{placeholder:'e.g. CEO.',title:'Input Position',required:'required'}"
																name="am_position" v-model="row.am_position"></BoField>
															<BoField v-bind="validation('am_mail')" :attr="{type:'email'}" name="am_mail"
																v-model="row.am_mail"></BoField>
															<BoField v-bind="validation('am_wa')" :attr="{type:'number'}" name="am_wa"
																v-model="row.am_wa"></BoField>
															<BoField name="am_large_img">
																<div class="row">
																	<div class="col-6">
																		<div class="row">
																			<radio name="am_large_img" :id="'i'" v-model="row.am_large_img" option="Y"
																				:attr="validation('am_large_img')">Large
																			</radio>
																			<radio name="am_large_img" :id="'n'" v-model="row.am_large_img" option="N">
																				Small
																			</radio>
																		</div>
																	</div>
																</div>
															</BoField>
															<BoField name="am_is_active">
																<div class="row">
																	<div class="col-6">
																		<div class="row">
																			<radio name="am_is_active" :id="'ii'" v-model="row.am_is_active" option="Y"
																				:attr="validation('am_is_active')">
																				Active</radio>
																			<radio name="am_is_active" :id="'nn'" v-model="row.am_is_active" option="N">
																				Inactive</radio>
																		</div>
																	</div>
																</div>
															</BoField>
														</div>
													</div>
												</div>
												<div class="modal-footer">
													<button type="submit" class="btn btn-rounded  btn-info btn-loading">Submit</button>
												</div>
											</VForm>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<div class="form-body" id="addCity">
							<h5 class="card-title">Dimatique Cities</h5>
							<hr>
							<div class="row">
								<div class="col-md-12">
									<div class="map_indo">
										<img :src="assets('bo_images','indo_map.png')" />
										<div class="map_indo__overlay" @click="addMap($event)"></div>
										<a v-for="(m,km) in dataMap" :key="km" @click="editMap(m)" class="point_map a1" :id="'map'+m.ac_id" data-trigger="hover"
												data-toggle="popover" data-placement="top" :title="m.ac_name" :data-content="m.ac_address"
												href="javascript:;" :style="'top:' +m.ac_y+'%;left:'+m.ac_x+'%;z-index:99;'"></a>
									</div>
								</div>
							</div>
							<div class="mt-3 alert alert-info">
								Click on the map to add a pinpoint.
							</div>
						</div>
						<!-- Modal -->
						<div id="add_city" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_team" aria-modal="true">
							<div class="modal-dialog">
								<div class="modal-content">
									<div class="modal-header">
										<h4 class="modal-title" id="prod_cat">City Address</h4>
										<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
									</div>
									<VForm @resp="submitMap" method="post">
										<div class="modal-body">
											<div class="row">
												<div class="col-12 info-modal"></div>
												<div class="col-md-12">
													<BoField name="ac_mrc_id" :label="'City'" mandatory>
														<div class="input-group">
															<select2 name="ac_mrc_id" v-bind="validation('ac_mrc_id')" :options="city"
																:object="['mrc_id','mrc_name']" v-model="row3.ac_mrc_id">
																<option value="">--Select City--</option>
															</select2>
															<div class="input-group-append">
																<button data-toggle="modal" data-target="#add_master_city" class="btn btn-secondary"
																	type="button" onclick="education_fields();"><i class="fas fa-plus"></i></button>
															</div>
														</div>
													</BoField>
												</div>
												<div class="col-md-12 mt-2">
													<BoField :label="'Title'" mandatory v-bind="validation('ac_name')"
														:attr="{title:'Input Full Name',required:'required',placeholder:'e.g. Jakarta'}"
														name="ac_name" v-model="row3.ac_name"></BoField>
												</div>
												<div class="col-md-12 mt-2">
													<div class="form-group">
														<label class="control-label">Full Address<span class="text-danger mr5">*</span> </label>
														<textarea required="required" minlength="3" maxlength="254" v-model="row3.ac_address"
															class="form-control" rows="5" placeholder="e.g. Sudirman Plaza"></textarea>
													</div>

													<BoField name="ac_is_active">
														<div class="row">
															<radio name="ac_is_active" :id="'ii'" v-model="row3.ac_is_active" option="Y"
																:attr="validation('ac_is_active')">Active</radio>
															<radio name="ac_is_active" :id="'nn'" v-model="row3.ac_is_active" option="N">Inactive
															</radio>
														</div>
													</BoField>
												</div>
											</div>
										</div>
										<div class="modal-footer">
											<button type="submit" class="btn btn-rounded  btn-info btn-loading">Submit</button>
										</div>
									</VForm>
								</div>
							</div>
						</div>

						<div id="add_master_city" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="add_master_city"
							aria-modal="true">
							<div class="modal-dialog">
								<div class="modal-content">
									<div class="modal-header">
										<h4 class="modal-title" id="add_master_city">Add Master City</h4>
										<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
									</div>
									<VForm @resp="submitCity" method="post">
										<div class="modal-body">
											<div class="row">
												<div class="col-12 info-modal"></div>
												<div class="col-md-12 mt-2">
													<BoField :label="'City'" mandatory v-bind="validation('mrc_name')"
														:attr="{title:'Input Name',required:'required',placeholder:'e.g. Jakarta'}" name="mrc_name"
														v-model="rowCity.mrc_name"></BoField>
												</div>
											</div>
										</div>
										<div class="modal-footer">
											<button type="submit" class="btn btn-rounded  btn-info btn-loading">Submit</button>
										</div>
									</VForm>
								</div>
							</div>
						</div>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										City
									</th>
									<th>Address</th>
									<th>Status</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in dataMap" :key="k">
									<td class="number">{{k+1}}</td>
									<td>{{v.ac_name}}</td>
									<td>{{v.ac_address}}</td>
									<td>
										<StatusLabel :status="v.status"></StatusLabel>
									</td>
									<td class="btn-action">
										<a href="javascript:;" class="icon_action" @click="editMap(v)" v-tooltip="'Edit'"><i
												class="ti-marker-alt"></i></a>
										<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)" v-tooltip="'Remove'"><i
												class="ti-trash"></i></a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>